(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/data/utils.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/data/utils.js');

'use strict';

let logger;
if (typeof exports === 'object') {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('accountservices:player-exclusions');
} else {
  logger = svs.core.log.getLogger('accountservices:player-exclusions');
}
function getDefinitions() {
  if (typeof exports === 'object') {
    return require('./definitions.es6').definitions;
  }
  return svs.accountservices.player_exclusions.definitions;
}
function getCompressor() {
  if (typeof exports === 'object') {
    return require('./compressor.es6');
  }
  return svs.accountservices.player_exclusions.compressor;
}
function getExclusion() {
  if (typeof exports === 'object') {
    return require('./exclusion.es6');
  }
  return svs.accountservices.player_exclusions;
}
function getDateFns() {
  if (typeof exports === 'object') {
    const trinidad = require('trinidad-core').core;
    return trinidad.components.require('libs', 'date-fns').api;
  }
  return dateFns;
}
const {
  verticals,
  pauseTypes,
  subSystems,
  restrictionReasons,
  gcsLimitations
} = getDefinitions();
const {
  Exclusion
} = getExclusion();
const dateFunctions = getDateFns();
const subSystemToVertical = [verticals.ALLA, verticals.BINGO, verticals.CASINO, verticals.POKER, verticals.SPORT, verticals.TIPSSPEL, verticals.ODDSET];
function getVerticalName(subSystemId) {
  return subSystems[subSystemId];
}
function getVertical(subSystemId) {
  return subSystemToVertical[subSystemId];
}
const isValidSubSystem = subSystem => {
  if (subSystem) {
    return subSystems.indexOf(String(subSystem).toLowerCase()) >= 0;
  }
  return false;
};
const pauseTypeIsGCS = pauseType => pauseType === pauseTypes.PauseTwentyFourHoursGcs || pauseType === pauseTypes.PauseTwelveMonthsGcs;
const isValidSubSystemID = subSystemID => subSystemID >= 1 && subSystemID <= 6;
const exclusionIsCacheable = exclusion => {
  if (exclusion) {
    if (exclusion.needSelfTest || exclusion.isExpired) {
      return false;
    } else if (exclusion.expiry && dateFunctions.isBefore(new Date(exclusion.expiry), new Date())) {
      return false;
    }
  }
  return true;
};
const exclusionsAreCacheable = activeExclusions => {
  const hasRestrictionActive = activeExclusions.hasGCSRestriction || activeExclusions.hasTTRestriction || activeExclusions.hasAMDRestriction || activeExclusions.hasTMDTLRestriction || activeExclusions.hasDLRRestriction;
  if (hasRestrictionActive) {
    return false;
  }
  if (activeExclusions.hasTotalPause && !exclusionIsCacheable(activeExclusions.alla)) {
    return false;
  }
  if (activeExclusions.hasVerticalPause) {
    const pausesToCheck = [activeExclusions.sport, activeExclusions.poker, activeExclusions.bingo, activeExclusions.casino];
    for (let i = 0, iLen = 4; i < iLen; i++) {
      if (!exclusionIsCacheable(pausesToCheck[i])) {
        return false;
      }
    }
  }
  return true;
};
function processVerticalExclusions(verticalExclusions, current) {
  let exclusions = verticalExclusions.filter(exclusion => isValidSubSystemID(parseInt(exclusion.exclusionGroup, 10)));
  if (!exclusions.length) {
    return current;
  }
  const now = new Date();
  const numberOfExclusions = exclusions.length;
  let filteredExclusions;
  let spelpaus24Expiry;
  let expectedLength;
  if (numberOfExclusions > 3) {
    filteredExclusions = exclusions.filter(ex => {
      if (ex.exclusionType === 'PauseTwentyFourHours') {
        spelpaus24Expiry = ex.expiry;
        return false;
      }
      return true;
    });
    if (typeof exports === 'object') {
      expectedLength = svs.featureSplitVertical || false ? 5 : 4;
    } else {
      expectedLength = svs.core.detect.feature('ft-split-sport-vertical') || false ? 5 : 4;
    }
    if (numberOfExclusions - filteredExclusions.length === expectedLength) {
      exclusions = filteredExclusions;
      if (!current.alla || dateFunctions.isBefore(new Date(current.alla.expiry), new Date(spelpaus24Expiry))) {
        current.alla = new Exclusion({
          expiry: spelpaus24Expiry,
          isExpired: false,
          type: pauseTypes.GamingPause24,
          vertical: verticals.ALLA
        });
        current.hasTotalPause = true;
        current.hasPause = true;
        current.hasVerticalPause = false;
      }
    }
  }
  if (exclusions.length) {
    if (!current.hasTotalPause) {
      current.hasTotalPause = false;
    }
    for (let i = 0, iLen = exclusions.length; i < iLen; i++) {
      const exclusion = exclusions[i];
      const verticalExclusionExpiry = new Date(exclusion.expiry);
      const exclusionGroup = parseInt(exclusion.exclusionGroup, 10);
      const name = getVerticalName(exclusionGroup);
      const newExclusion = current[name] || new Exclusion({
        vertical: getVertical(exclusionGroup)
      });
      let change;
      switch (exclusion.exclusionType) {
        case 'PauseTwelveMonthsGcs':
          change = true;
          newExclusion.needSelfTest = true;
          newExclusion.selfTestExpiry = exclusion.expiry;
          break;
        default:
          if ((!current.alla || dateFunctions.isBefore(new Date(current.alla.expiry), verticalExclusionExpiry)) && (!newExclusion.expiry || dateFunctions.isBefore(new Date(newExclusion.expiry), verticalExclusionExpiry))) {
            newExclusion.expiry = exclusion.expiry;
            newExclusion.isExpired = dateFunctions.isAfter(now, verticalExclusionExpiry);
            newExclusion.type = pauseTypes[exclusion.exclusionType];
            change = true;
          }
      }
      if (change) {
        current.hasVerticalPause = true;
        current.hasPause = true;
        current[name] = newExclusion;
      }
    }
  }
  return current;
}
function processTotalExclusion(totalExclusion, current) {
  const now = new Date();
  if (current.hasVerticalPause) {
    const totalExpiry = new Date(totalExclusion.expiry);
    ['sport', 'casino', 'bingo', 'poker'].forEach(item => {
      const vert = current[item];
      if (vert && vert.expiry && dateFunctions.isAfter(totalExpiry, new Date(vert.expiry))) {
        delete current[item];
        current.hasVerticalPause = ['sport', 'casino', 'bingo', 'poker'].some(vertical => current[vertical]);
      }
    });
  } else {
    current.hasVerticalPause = false;
  }
  current.hasPause = true;
  current.hasTotalPause = true;
  if (!current.alla || dateFunctions.isAfter(new Date(totalExclusion.expiry), new Date(current.alla.expiry))) {
    current.alla = new Exclusion({
      expiry: totalExclusion.expiry,
      isExpired: dateFunctions.isAfter(now, new Date(totalExclusion.expiry)),
      type: pauseTypes[totalExclusion.exclusionType],
      vertical: verticals.ALLA
    });
  }
  return current;
}

const parseGCSRestrictionData = (reason, birthDate) => {
  let isYoungster;
  let expiryDate;
  let is12hr = false;
  try {
    const metaData = (reason === null || reason === void 0 ? void 0 : reason.metaData) !== '' && reason !== null && reason !== void 0 && reason.metaData ? JSON.parse(reason.metaData) : {};
    expiryDate = reason && reason.autoExpiryDate ? dateFunctions.format(new Date(reason.autoExpiryDate), 'HH:mm') : undefined;
    const age = dateFunctions.differenceInYears(new Date(), dateFns.parseISO(birthDate));
    isYoungster = age && age < gcsLimitations.gcsCutoffAge;
    is12hr = metaData !== null && metaData !== void 0 && metaData.statusId ? gcsLimitations.gcsStatus.twelveHourPauseIds.includes(metaData === null || metaData === void 0 ? void 0 : metaData.statusId) : undefined;
  } catch (e) {
    logger.error("parseGCSRestrictionData encounterd an error parsing restriction data: ".concat(JSON.stringify(e)));
    return {
      error: e
    };
  }
  return {
    isYoungster,
    expiryDate,
    is12hr
  };
};
const processRestriction = (data, current) => {
  const restrictions = data.accountRestrictions;
  restrictions.forEach(restriction => {
    restriction.reasons && restriction.reasons.forEach(reason => {
      switch (reason === null || reason === void 0 ? void 0 : reason.reason) {
        case restrictionReasons.TT:
          current.hasTTRestriction = true;
          break;
        case restrictionReasons.GCS:
          current.hasGCSRestriction = true;
          current.gcsData = parseGCSRestrictionData(reason, data.birthDate);
          break;
        case restrictionReasons.DLR:
          current.hasDLRRestriction = true;
          break;
        case restrictionReasons.AMD:
          current.hasAMDRestriction = true;
          break;
        case restrictionReasons.TMDTL:
          current.hasTMDTLRestriction = true;
          break;
        default:
          return false;
      }
    });
  });
  return current;
};
const processNARPause = (data, current) => {
  if (typeof (data === null || data === void 0 ? void 0 : data.isBlocked) === 'function') {
    current.isBlocked = Boolean(data.isBlocked());
  } else if (data && Object.hasOwnProperty.call(data, 'isBlocked')) {
    current.isBlocked = Boolean(data.isBlocked);
  }
  return current;
};
const hasVerticalExclusions = data => Array.isArray(data.verticalExclusions) && data.verticalExclusions.length;
const hasTotalExclusion = data => data.totalExclusion && data.totalExclusion.expiry;
const hasRestriction = data => {
  var _data$accountRestrict;
  return (_data$accountRestrict = data.accountRestrictions) === null || _data$accountRestrict === void 0 ? void 0 : _data$accountRestrict.length;
};

const processAPIData = function (data) {
  let currentExclusions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    hasPause: false,
    isBlocked: false,
    hasTTRestriction: false,
    hasGCSRestriction: false,
    hasDLRRestriction: false,
    hasTMDTLRestriction: false,
    hasAMDRestriction: false
  };
  if (data) {
    if (hasVerticalExclusions(data)) {
      currentExclusions = processVerticalExclusions(data.verticalExclusions, currentExclusions);
    }
    if (hasTotalExclusion(data)) {
      currentExclusions = processTotalExclusion(data.totalExclusion, currentExclusions);
    }
    if (hasRestriction(data)) {
      currentExclusions = processRestriction(data, currentExclusions);
    }
    currentExclusions = processNARPause(data, currentExclusions);
  }
  return currentExclusions;
};
const asArray = function () {
  let activeExclusions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let array = [];
  if (activeExclusions.hasPause) {
    array = Object.keys(activeExclusions).filter(key => activeExclusions[key] && Boolean(subSystems.indexOf(key) !== -1) && activeExclusions[key].expiry).sort().map(filteredKey => {
      let expiry = activeExclusions[filteredKey].expiry;
      const parsedDate = dateFns.parseISO(expiry);
      if (filteredKey === 'alla' && activeExclusions[filteredKey].type !== pauseTypes.GamingPause24) {
        expiry = dateFns.formatISO(dateFns.subHours(parsedDate, 12));
      }
      const timeFormat = filteredKey === 'alla' && activeExclusions[filteredKey].type !== pauseTypes.GamingPause24 ? '' : ' HH:mm';
      const textFormatTime = timeFormat !== '' && dateFns.format(dateFns.parseISO(expiry), timeFormat, {
        locale: dateFns.locales.sv
      });
      const textFormatDate = dateFns.format(dateFns.parseISO(expiry), 'd MMMM yyyy', {
        locale: dateFns.locales.sv
      });
      const listFormatDate = dateFns.format(dateFns.parseISO(expiry), 'yyyy-MM-d');
      return {
        expiry,
        isExpired: activeExclusions[filteredKey].isExpired,
        isGCS: pauseTypeIsGCS(activeExclusions[filteredKey].type),
        textFormatDate: timeFormat === '' ? textFormatDate : "".concat(textFormatDate, " kl. ").concat(textFormatTime),
        listFormatDate: timeFormat === '' ? listFormatDate : "".concat(listFormatDate, " kl. ").concat(textFormatTime),
        subSystem: filteredKey === 'alla' ? 'Alla spel' : filteredKey.charAt(0).toUpperCase() + filteredKey.substring(1)
      };
    });
  }
  return array;
};
const api = {
  asArray,
  isValidSubSystem,
  pauseTypes,
  parseGCSRestrictionData,
  processAPIData,
  exclusionsAreCacheable
};
if (typeof exports === 'object') {
  module.exports = api;
} else {
  svs.accountservices.player_exclusions.utils = api;
}

 })(window);